/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { useLayout } from '../core'

const Footer: FC = () => {
  const { classes } = useLayout()
  return (
    <div className='footer py-4 px-7 d-flex flex-lg-column' id='kt_footer'>
      {/* begin::Container */}
      <div
        className={`${classes.footerContainer} d-flex flex-column flex-md-row align-items-center justify-content-between`}
      >
        {/* begin::Copyright */}
        <div className='text-dark order-2 order-md-1'>
          <span className='text-muted fw-bold me-2'>{new Date().getFullYear()} &copy;</span>
          <a href='#' className='fs-5 text-gray-600 text-hover-primary'>
            Netzac LLC
          </a>
        </div>
        {/* end::Copyright */}

        {/* begin::Nav */}
        <ul className='menu menu-gray-600 d-flex flex-center gap-4 menu-hover-primary order-1'>
          <li className='menu-item'>
            <span className='menu-content pe-5 fs-5 text-gray-600'>
              Connect with us on
            </span>
          </li>
          <li className='menu-item'>
            <a href='#' className='menu-link btn btn-icon btn-circle btn-sm border btn-active-color-primary border-gray-300 fs-9'>
              <i className="bi bi-twitter-x fs-5"></i>
            </a>
          </li>
          <li className='menu-item'>
            <a href='#' className='menu-link btn btn-icon btn-circle btn-sm border btn-active-color-primary border-gray-300 fs-9'>
              <i className="bi bi-linkedin fs-5"></i>
            </a>
          </li>
          <li className='menu-item'>
            <a href='#' className='menu-link btn btn-icon btn-circle btn-sm border btn-active-color-primary border-gray-300 fs-9'>
              <i className="bi bi-instagram fs-5"></i>
            </a>
          </li>
        </ul>
        {/* end::Nav */}
      </div>
      {/* end::Container */}
    </div>
  )
}

export { Footer }
