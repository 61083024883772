
export interface INetzacInfo {
  businessName: string
  businessDescriptor: string
  businessType: string
  businessDescription: string
  businessEmail: string
}


const inits: INetzacInfo = {
  businessName: 'Netverse LLC',
  businessDescriptor: 'Netverse',
  businessType: '5',
  businessDescription: '',
  businessEmail: 'netzac@support.com',
}

export { inits}
