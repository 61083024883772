import { FC } from 'react'
import { Field } from 'formik'

const Step3: FC = () => {
  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-12'>
        <h2 className='fw-bolder text-dark'>Business Details</h2>
      </div>

      <div className='fv-row mb-10'>
        <label className='form-label'>Business Name</label>

        <Field name='businessName'
          className='form-control form-control-lg form-control-disabled form-control-solid'
          disabled
        />
      </div>

      <div className='fv-row mb-10'>
        <label className='d-flex align-items-center form-label'>
          <span className=''>Shortened Descriptor</span>
        </label>

        <Field
          name='businessDescriptor'
          className='form-control form-contol-disabled form-control-lg form-control-solid'
          disabled
        />

        <div className='form-text'>
          Clients reference us with this shortened version of your company descriptor
        </div>
      </div>

      {/*<div className='fv-row mb-10'>*/}
      {/*  <label className='form-label'>Corporation Type</label>*/}

      {/*  <Field*/}
      {/*    as='select'*/}
      {/*    name='businessType'*/}
      {/*    className='form-select form-select-lg form-select-solid'*/}
      {/*    disabled*/}
      {/*  >*/}
      {/*    <option></option>*/}
      {/*    <option value='1'>S Corporation</option>*/}
      {/*    <option value='1'>C Corporation</option>*/}
      {/*    <option value='2'>Sole Proprietorship</option>*/}
      {/*    <option value='3'>Non-profit</option>*/}
      {/*    <option value='4'>Limited Liability</option>*/}
      {/*    <option value='5'>General Partnership</option>          */}
      {/*  </Field>*/}
      {/*</div>*/}

      <div className='fv-row mb-0'>
        <label className='fs-6 fw-bold form-label'>Contact Email</label>

        <Field name='businessEmail'
          className='form-control disabled form-control-lg form-control-solid'
          disabled
        />
      </div>
    </div>
  )
}

export { Step3 }
