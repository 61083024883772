import { KTSVG, toAbsoluteUrl } from "../../../../../_metronic/helpers";


type ImageActionButtonProps = {
    onClick: () => void,
    iconPath: string,
}

type ImageSelectionProps = {
    onClick: () => void,
    imageSrc: string,
    isSelected: boolean,
}


const ImageActionButton = (
    {
        onClick,
        iconPath,
    }: ImageActionButtonProps
) => {
    return (
        <div className="btn btn-icon btn-circle btn-active-color-primary bg-secondary" onClick={onClick}>
            <KTSVG
                path={toAbsoluteUrl(iconPath)}
                className='svg-icon-1 svg-icon-primary'
            />
        </div>
    );
}


const ImageSelectionItem = (
    {
        onClick,
        imageSrc,
        isSelected,
    }: ImageSelectionProps
) => {
    return (
        <div className={`d-flex flex-column cursor-pointer justify-content-center align-items-center w-xl-100px w-100px w-lg-100px h-auto ${isSelected ? "border border-2 border-primary rounded" : ""}`} onClick={onClick}>
            <div className='justify-content-center w-xl-100px w-100px w-lg-100px w-sm-100px h-auto rounded py-2 px-2 '>
                <div className='symbol symbol-100px symbol-lg-100px symbol-sm-100px '>
                    <img src={`/media/illustrations/netzac/jSchoolScreenshots/`+ imageSrc} alt="" className="w-100 h-auto" />
                </div>
            </div>
        </div>
    );
}


export { ImageActionButton, ImageSelectionItem };