import { KTSVG } from "../../../../_metronic/helpers"

type SolutionTypeProps = {
    title: string
    progress: string
    color: string
    className: string
    svgIcon: string
    iconColor: string
}

const SolutionsWidget: React.FC<SolutionTypeProps> = ({
    title,
    progress,
    color,
    svgIcon,
    iconColor,
    className,
}) => {
    return (
        <span className={`card bg-light-${color} hoverable ${className}`}>
            <div className='card-body'>
                <KTSVG path={svgIcon} className={`svg-icon-${iconColor} svg-icon-3x ms-n1`} />

                <div className={`card-title fw-bold text-${color} fs-5 mb-2 mt-5 d-block`}>{title}</div>

                <div className='py-1'>
                    <span className='text-dark fs-1 fw-bold me-2'>{progress}</span>

                    <span className='fw-semibold text-gray-800 fs-7'>Complete</span>
                </div>

                <div className={`progress h-7px bg-${color} bg-opacity-50 mt-7`}>
                    <div
                        className={`progress-bar bg-${color}`}
                        role='progressbar'
                        style={{ width: progress }}
                    />
                </div>
            </div>
        </span>
    )
}

export { SolutionsWidget }