import { ResourceCard } from "./ResourceCard";

const ResourcesContent = () => {
    return (
        <div className="d-flex flex-column flex-root gap-5 pb-9" id="ablout-netzac">
            <div className="d-flex flex-column align-items-start">
                <div className="d-flex flex-row align-items-center text-gray-800 mb-2">
                    <i className="bi bi-mortarboard-fill fs-2 text-gray-900 "></i>
                    <h3 className="fw-bolder  ps-5 ls-2">Resources</h3>
                </div>
                <p className="fs-4 text-gray-800 mb-10">
                    We provide a variety of resources, lookout for something you might find interesting.
                </p>
            </div>
            <div className='row g-6 g-xl-9'>
                <div className='col-sm-6 col-xl-3'>
                    <ResourceCard
                        title='Customer Support'
                        description='Contact us for any enquiries and info you need'
                        icon='/media/illustrations/netzac/intro-to-ai-ethics.svg'
                    />
                </div>
                <div className='col-sm-6 col-xl-3'>
                    <ResourceCard
                        title='Browse job openings'
                        description='We might need some extra hands out there'
                        icon='/media/illustrations/netzac/natural-language-processing-guide.svg'
                    />
                </div>
                <div className='col-sm-6 col-xl-3'>
                    <ResourceCard
                        title='Share feedback'
                        description='Tell us about any experience you’ve had with us'
                        icon='/media/illustrations/netzac/researchers-illo.svg'
                    />
                </div>
                <div className='col-sm-6 col-xl-3'>
                    <ResourceCard
                        title='Guides & tutorials'
                        description='Get tips and more.'
                        icon='/media/illustrations/netzac/learning-resources.svg'
                    />
                </div>
                <div className='col-sm-6 col-xl-3'>
                    <ResourceCard
                        title='User manuals'
                        description='Navigate your way with our guides'
                        icon='/media/illustrations/netzac/intro-to-programming.svg'
                    />
                </div>
                <div className='col-sm-6 col-xl-3'>
                    <ResourceCard
                        title='How-to videos'
                        description='Watch and learn how to do things.'
                        icon='/media/illustrations/netzac/learn-guide-image-models.svg'
                    />
                </div>
            </div>
        </div>

    );
}

export { ResourcesContent };
