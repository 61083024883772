interface StaffModel {
    image: string;
    name: string;
    jobTitle: string;
    online: boolean;
    color?: string;
    linkedIn?: string;
}

const listOfStaff: Array<StaffModel> = [
    {
        image: '',
        name: 'Fadel Adoe',
        jobTitle: 'CEO, Chief Technical Director',
        online: true,
        color: "danger",
        linkedIn: 'www.linkedin.com/in/fadel-adoe'
    },
    {
        image: '',
        name: 'Joe Louis',
        jobTitle: 'Implementation Manager',
        online: true,
        color: 'warning'
    },
    {
        image: '',
        name: 'Kofi K. Vincent',
        jobTitle: 'Production & QA Manager',
        online: true,
        color: 'primary'
    },
    {
        image: 'samuel_owusu.jpeg',
        name: 'Samuel Owusu Adjei',
        jobTitle: 'Director - Marketing',
        online: true,
        color: 'success'
    },
 
]

export { listOfStaff }