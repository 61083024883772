/* eslint-disable jsx-a11y/anchor-is-valid */

import { Link } from 'react-router-dom'
import { KTSVG } from '../../../../../_metronic/helpers'


const JSchoolHeader: React.FC = () => {
  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_jSchoolAnywhere'
        aria-expanded='true'
        aria-controls='kt_jSchoolAnywhere'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder fs-2hx text-danger m-0'>jSchoolAnywhere</h3>
        </div>
      </div>

      <div id='kt_jSchoolAnywhere' className='collapse show'>
        <div className='card-body border-top p-9'>
          <div className='notice d-flex bg-light-primary rounded border-primary border border-dashed mb-9 p-6'>
            <KTSVG
              path='/media/icons/duotune/art/art006.svg'
              className='svg-icon-2tx svg-icon-primary me-4'
            />
            <div className='d-flex flex-stack flex-grow-1'>
              <div className='fw-bold'>
                <div className='fs-3 text-gray-600'>
                  Your integrated, cloud-based solution for a dynamic learning environment.
                  <a href='http://jschoolanywhere.pythonanywhere.com/' rel="noreferrer" target="_blank" className='fw-bolder ps-2'>
                    Try it now
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className='py-2'>
            <div className='d-flex flex-wrap align-items-center fs-3 fw-bold text-gray-700'>
              <span>Igniting Educational Excellence to </span>
              <div className='d-flex ps-2'>
                <li className="d-flex align-items-center pe-3">
                  <span className="bullet bg-primary me-2"></span>Empower
                </li>
                <li className="d-flex align-items-center pe-3">
                  <span className="bullet bg-danger me-2"></span>Relish
                </li>
                <li className="d-flex align-items-center pe-3">
                  <span className="bullet bg-info me-2"></span>Tailor
                </li>
                <li className="d-flex align-items-center pe-3">
                  <span className="bullet bg-success me-2"></span> and Scale.
                </li>
              </div>
            </div>
            <span className='fs-3 fw-bold text-gray-700'>We’re transforming the educational landscape, one cloud at a time.</span>
            <br />
            <Link className="btn btn-primary" to='/portal-modules'>
              view portal modules
            </Link>
          </div>
        </div>
      </div>
    </div >
  )
}

export { JSchoolHeader }
