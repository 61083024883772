import { useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { MenuComponent } from '../assets/ts/components'

import { Content } from './components/Content'
import { Footer } from './components/Footer'
import { ScrollTop } from './components/ScrollTop'
import { PageDataProvider } from './core'
import { HeaderWrapper2 } from './components/header/HeaderWrapper2'


const MasterLayout = () => {
  const location = useLocation()
  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [location.key])

  return (
    <PageDataProvider>
        <div className='page d-flex flex-row flex-column-fluid'>
          <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
            <HeaderWrapper2 />

            <div id='kt_content' className='content d-flex flex-column flex-column-fluid pb-9'>
              {/* <Toolbar /> */}
              <div className='post d-flex flex-column-fluid' id='kt_post'>
                <Content>
                  <Outlet />
                </Content>
              </div>
            </div>
            <Footer />
          </div>
        </div>
        <ScrollTop />
    </PageDataProvider>
  )
}

export { MasterLayout }
