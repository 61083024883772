import { Link, useLocation } from "react-router-dom"
import { toAbsoluteUrl } from "../../../_metronic/helpers"

const NetZacHeader = () => {
    const location = useLocation()
    return (
        <div
            className='card mb-5 mb-xl-10 border border-2 border-gray-300 mt-9'
            style={{
                backgroundImage: `linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.9)), url(${toAbsoluteUrl('/media/illustrations/netzac/headBg.jpg')})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundBlendMode: 'multiply',
                backgroundColor: 'rgba(0, 0, 0, 0.6)',
            }}
        >
            <div className='card-body pt-9 pb-0'>
                <div className='d-flex flex-column pb-9 column-center justify-content-center align-items-center'>
                    <div className="d-flex flex-column gap-5 align-items-center w-xl-800px w-lg-800px w-100">
                        <h1 className="fw-bolder fs-4hx text-light w-100  ls-2 text-align-center"

                        ><span className='text-primary'>Netverse,</span> Your Gateway to Future Education</h1>
                        <div className='py-7 px-6 w-xl-800px w-lg-500px w-75 bg-filter-6 bg-style-2'>
                            <img src={toAbsoluteUrl('/media/illustrations/netzac/l-shape.svg')}
                                alt='about illustration'
                                className="w-xl-200px w-lg-200px h-xl-200px h-lg-200px w-135px h-135px image-top z-index-n1" 
                                style={{ transform: 'scaleX(-1)' }}
                                />

                            <img src={toAbsoluteUrl('/media/illustrations/netzac/l-shape.svg')}
                                alt='about illustration'
                                className="w-xl-200px w-lg-200px h-xl-200px h-lg-200px w-135px h-135px image-bottom z-index-n1"
                                style={{ transform: 'scaleY(-1)' }}
                            />

                            <p className="fs-3 text-light text-align-center">
                            Netverse is revolutionizing the educational landscape, offering an all-in-one Edu Portal that
                                seamlessly integrates Commerce, Finance, and Adaptive Mock Tests.
                            </p>
                        </div>
                    </div>
                </div>

                <div className='d-flex overflow-auto h-55px text-primary '>
                    <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                        <li className='nav-item'>
                            <Link
                                className={
                                    `nav-link text-active-primary me-6 ` +
                                    (location.pathname === '/home/aboutNetzac' && 'active')
                                }
                                to='/home/aboutNetzac'
                            >
                                About Netverse
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link
                                className={
                                    `nav-link text-active-primary me-6 ` +
                                    (location.pathname === '/home/solutions' && 'active')
                                }
                                to='/home/solutions'
                            >
                                Solutions
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link
                                className={
                                    `nav-link text-active-primary me-6 ` +
                                    (location.pathname === '/home/resources' && 'active')
                                }
                                to='/home/resources'
                            >
                                Resources
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link
                                className={
                                    `nav-link text-active-primary me-6 ` +
                                    (location.pathname === '/home/reachUs' && 'active')
                                }
                                to='/home/reachUs'
                            >
                                Reach us
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export { NetZacHeader }