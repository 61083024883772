import { useState } from "react";
import { KTSVG, toAbsoluteUrl } from "../../../../../_metronic/helpers";
import { ScreenShotModel } from "../ScreenShotModels";
import { ImageActionButton, ImageSelectionItem } from "./ImageActionButtons";
import { JSchoolHeader } from "./JSchoolAccordion";
import { CopiableItem } from "./CopiableItem";
import { SolutionsWidget } from "../SolutionTypes";
import './Solutions.css';


const SolutionsContent = () => {

    const [selectedImage, setSelectedImage] = useState<ScreenShotModel>(jschoolanywhereScreenshots[0])

    const selectImage = (image: ScreenShotModel) => {
        setSelectedImage(image)
    }



    return (
        <div className="d-flex flex-column  flex-root gap-5" id="about-netzac">
            <div className="d-flex flex-row align-items-center text-gray-800 mb-2">
                <KTSVG path={toAbsoluteUrl('/media/icons/duotune/general/gen062.svg')} className={`svg-icon-2x svg-icon-white ms-n1 me-2`} />
                <h3 className='fw-bolder my-2'>
                    Our Solutions
                    <span className='fs-6 text-gray-400 fw-bold ms-2'>Currently active</span>
                </h3>
            </div>
            <div className='row g-5 g-xl-8'>
                <div className='col-xl-4'>
                    <SolutionsWidget
                        className='card-xl-stretch mb-xl-8'
                        color='danger'
                        title='jSchoolAnywhere'
                        progress='100%'
                        svgIcon='/media/icons/duotune/general/gen001.svg'
                        iconColor='white'
                    />
                </div>

                <div className='col-xl-4'>
                    <SolutionsWidget
                        className='card-xl-stretch mb-xl-8'
                        color='success'
                        title='Inventory System'
                        progress='70%'
                        svgIcon='/media/icons/duotune/ecommerce/ecm008.svg'
                        iconColor='white'
                    />
                </div>

                <div className='col-xl-4'>
                    <SolutionsWidget
                        className='card-xl-stretch mb-xl-8'
                        color='primary'
                        title='Other system'
                        progress='50%'
                        svgIcon='/media/icons/duotune/general/gen022.svg'
                        iconColor='white'
                    />
                </div>
            </div>
            <JSchoolHeader />
            <div className="d-flex flex-column justify-content-center align-items-center gap-7 py-9">
                <div className='w-100 w-xl-80 w-lg-80 w-sm-100 h-xl-auto h-lg-auto h-sm-auto h-auto border border-1 border-gray-500 bg-light rounded'>
                    <div className='symbol symbol-100 symbol-lg-100 symbol-sm-100 d-flex flex-column justify-content-center align-items-center px-4 py-4 '>
                        <img src={`/media/illustrations/netzac/jSchoolScreenshots/` + selectedImage.imageSrc}
                            alt=''
                            className="w-100 h-auto" />
                    </div>
                </div>

                <div className="d-flex flex-column justify-content-center">
                    <div className="d-flex flex-row gap-5 justify-content-center align-items-center w-xl-100 w-100 w-lg-500px py-4">
                        <ImageActionButton
                            onClick={() => { console.log("previous images scroll horizontally") }}
                            iconPath="/media/icons/duotune/arrows/arr074.svg"
                        />
                        {
                            jschoolanywhereScreenshots.map((image, index) => {
                                return (
                                    <ImageSelectionItem
                                        onClick={() => { selectImage(image) }}
                                        imageSrc={image.imageSrc}
                                        isSelected={selectedImage === image}
                                    />
                                )
                            })
                        }

                        <ImageActionButton
                            onClick={() => { console.log("next images scroll horizontally") }}
                            iconPath="/media/icons/duotune/arrows/arr071.svg"
                        />
                    </div>
                </div>
            </div>
            <div className="alert bg-light-primary d-flex flex-center flex-column py-10 px-10 px-lg-20 mb-10">

                <span className="svg-icon svg-icon-5tx svg-icon-danger mb-5">
                    <KTSVG
                        path={toAbsoluteUrl('/media/icons/duotune/general/gen025.svg')}
                        className='svg-icon-1 svg-icon-primary'
                    />
                </span>

                <h5 className="fw-bolder fs-1 mb-5 d-flex flex-center">Try a live demo!</h5>

                <div className="text-center">

                    <div className="separator separator-dashed border-primary opacity-25 mb-5"></div>
                    <div className="d-flex flex-column flex-center">
                        <div className="mb-5 text-center fs-3">
                            Explore and feel the our educational solution <strong className="text-danger">jSchoolAnywhere</strong><br />
                            <span className="text-gray-600">Login with the following credentials:</span>
                        </div>
                        <div className='fv-row w-100'>
                            <div className='row'>
                                <div className='col-lg-6'>
                                    <CopiableItem
                                        itemText="tester@gmail.com"
                                        description="Email"
                                        iconPath="/media/icons/duotune/communication/com011.svg"
                                    />
                                </div>
                                <div className='col-lg-6'>
                                    <CopiableItem
                                        itemText="tester"
                                        description="Password"
                                        iconPath="/media/icons/duotune/general/gen051.svg"
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="d-flex flex-center flex-wrap">
                            <a href="http://jschoolanywhere.pythonanywhere.com/" rel="noreferrer" target="_blank" className="btn btn-primary m-2">Try this now</a>
                        </div>
                    </div>
                </div>
            </div>
          </div >
           );
}

const jschoolanywhereScreenshots: Array<ScreenShotModel> = [
    {
        description: "Screenshot 1",
        imageSrc: "netzac1.jpg"
    },

    {
        description: "Screenshot 2",
        imageSrc: "netzac2.jpg"
    },

    {
        description: "Screenshot 3",
        imageSrc: "netzac3.jpg"
    },
]
export { SolutionsContent };



