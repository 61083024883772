import { Navigate, Route, Routes } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import HomePageContent from '../modules/netzac/HomePageContent'
import { WithChildren } from '../../_metronic/helpers'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { FC, Suspense } from 'react'
import TopBarProgress from 'react-topbar-progress-indicator'
import PortalModules from '../modules/netzac/solutionsSection/components/PortalModules'
import { PageTitle } from '../../_metronic/layout/core'


const PrivateRoutes = () => {

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path='/*' element={<Navigate to='/home' />} />
        {/* <Route path='home' element={<SiteContent />} /> */}
        <Route path='home/*' element={
          <SuspensedView>
          <HomePageContent />
        </SuspensedView>
        } />
        <Route path='*' element={<Navigate to='/error/404' />} />

        <Route
        path='portal-modules'
        element={
          <>
            <PageTitle>{'Portal Modules'}</PageTitle>
            <PortalModules />
          </>
        }
      />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}


export { PrivateRoutes }

