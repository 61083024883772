import React, { FC } from 'react'
import { KTSVG } from '../../../../../../_metronic/helpers'


const Step2: FC = () => {
  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-15'>
        <h2 className='fw-bolder text-dark'>Mission, Vision and Goals</h2>

        <div className='text-gray-400 fw-bold fs-6'>
          What we stand for and represent as a company
        </div>
      </div>
      <div className='fv-row h-100'>
        <div className='row h-100'>
          <div className='col-lg-6'>
            <label
              className='btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center '
            >
              <KTSVG
                path='/media/icons/duotune/general/gen055.svg'
                className='svg-icon-3x me-5'
              />

              <span className='d-block fw-bold text-start'>
                <span className='text-dark fw-bolder d-block fs-4 mb-2'>Mission</span>
                <span className='text-gray-400 fw-bold fs-6 text-align-justify'>
                  To leverage technological innovations to empower individuals and corportations to perform tasks easily,enjoyably,
                  and efficiently with the least possible human effort.
                </span>
              </span>
            </label>
          </div>

          <div className='col-lg-6'>
        
            <label
              className='btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center '
            >
              <KTSVG path='/media/icons/duotune/general/gen022.svg' className='svg-icon-3x me-5' />

              <span className='d-block fw-bold text-start'>
                <span className='text-dark fw-bolder d-block fs-4 mb-2'>Vision</span>
                <span className='text-gray-400 fw-bold fs-6'>
                  To enable individuals and corporations apply the optimal minimum human efforts letting computer hardwares and softwares do the heavy lifting.e
                </span>
              </span>
            </label>
          </div>
        </div>
      </div>
    </div>
  )
}

export { Step2 }


