/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { KTSVG } from '../../../../../../_metronic/helpers'
import { Link } from 'react-router-dom'


const Step1: FC = () => {
  return (
    <div className='w-100'>
      <div className='pb-lg-15'>
        <h2 className='fw-bolder d-flex align-items-center text-dark'>
          About Netverse
          <i
            className='fas fa-exclamation-circle ms-2 fs-7'
            data-bs-toggle='tooltip'
            title='Brief description of Netverse'
          ></i>
        </h2>
      </div>

      <div className='mb-0'>
        <div className='fs-6 text-gray-600 mb-5'>
        Netverse is revolutionizing the educational landscape, offering an all-in-one Edu Portal that seamlessly integrates Commerce, Finance, and Adaptive Mock Tests.
        </div>

        <div className='notice d-flex bg-light-info rounded rotate border-info border border-dashed p-6'>
          <KTSVG
            path='/media/icons/duotune/general/gen044.svg'
            className='svg-icon-2tx svg-icon-info me-4 rotate-90'
          />
          <div className='d-flex flex-stack flex-grow-1'>
            <div className='fw-bold'>
              <h4 className='text-gray-800 fw-bolder'>Did you know!</h4>
              <div className='fs-6 text-gray-600'>
                A pioneer in basic education in Africa? Netzac is leading the way in next-generation
                  {' '}
                  <Link
                    className= 'fw-bolder text-hover-gray-600 text-primary me-6'
                    to='/home/solutions'
                  >
                   See our solutions
                  </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export { Step1 }
