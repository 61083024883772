/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {toAbsoluteUrl, KTSVG} from '../../../../../_metronic/helpers'

type Props = {
  color?: string
  avatar?: string
  online?: boolean
  name: string
  job: string
  linkedinUrl?: string
  className?: string
}

const StaffCard: FC<Props> = ({
                                color = '',
                                avatar = '',
                                online = false,
                                name,
                                job,
                                linkedinUrl = '',
                                className = '',
                              }) => {
  return (
    <div className={`card ${className}`}>
      <div className="card-body d-flex flex-center flex-column p-9">
        <div className="mb-5">
          <div className="symbol symbol-75px symbol-circle">
            {color !== '' && avatar === '' ? (
              <span className={`symbol-label bg-light-${color} text-${color} fs-5 fw-bolder`}>
                {name.charAt(0)}
              </span>
            ) : (
              <img alt="Pic" src={toAbsoluteUrl(`/media/illustrations/netzac/images/${avatar}`)} />
            )}
            {online && (
              <div className="symbol-badge bg-success start-100 top-100 border-4 h-15px w-15px ms-n3 mt-n3"></div>
            )}
          </div>
        </div>

        <a href="#" className="fs-4 text-gray-800 text-hover-primary fw-bolder mb-0">
          {name}
        </a>

        <div className="fw-bold text-gray-400 mb-6">{job}</div>

        <a href={linkedinUrl} className="btn btn-sm btn-light">
          <KTSVG path="/media/icons/duotune/arrows/arr075.svg" className="svg-icon-3" />
          Connect on Linkedin
        </a>
      </div>
    </div>
  )
}


const StaffCard2: FC<Props> = ({
                                 color = '',
                                 avatar = '',
                                 online = false,
                                 name,
                                 job,
                                 linkedinUrl = '',
                                 className,
                               }) => {
  return (
    <div className={`d-flex flex-column flex-center ${className}`}>
      <div
        className="octagon h-100px w-100px w-lg-150px h-lg-150px w-sm-100px h-sm-100px w-xl-225px h-xl-225px bgi-no-repeat bgi-size-cover">
        {color !== '' && avatar === '' ? (
          <div className={`bg-light-${color} fs-5 fw-bolder w-100 h-100 justify-content-center d-flex flex-center`}>
            <span className={`text-${color} fs-3x`}>
                {name.charAt(0)}
              </span>
          </div>
        ) : (
          <img src={toAbsoluteUrl(`/media/illustrations/netzac/images/${avatar}`)} alt={name}
               className="h-100px w-100px w-lg-150px h-lg-150px w-sm-100px h-sm-100px w-xl-225px h-xl-225px" />
        )}
      </div>

      <a href="#" className="fs-3 text-gray-800 text-hover-primary fw-bolder mt-7 mb-2">
        {name}
      </a>

      <div className="fw-bold text-gray-400 mb-6">{job}</div>
    </div>
  )
}
export {StaffCard, StaffCard2}
