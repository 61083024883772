import { FC, useCallback, useState } from "react"
import { KTSVG, toAbsoluteUrl } from "../../../../../_metronic/helpers"

type CopiableItemProps = {
    itemText: string
    description: string
    iconPath: string
}

const CopiableItem: FC<CopiableItemProps> = ({
    itemText,
    description,
    iconPath,
}) => {
    const [isTextCopied, setIsTextCopied] = useState<Boolean>(false)

    const handleCopyClick = useCallback((event: React.MouseEvent) => {
        event.preventDefault();
        navigator.clipboard.writeText(itemText)
            .then(() => {
                setIsTextCopied(true)
                console.log('Text copied to clipboard');
            })
            .catch(err => {
                setIsTextCopied(false)
                console.error('Could not copy text: ', err);
            });
    }, [itemText]);

    return (
        <div className='d-flex align-items-center justify-content-between gap-5 border border-primary border-dashed rounded py-3 px-4 mb-3'>
            <span className='svg-icon svg-icon-2tx svg-icon-primary'>
                <KTSVG path={toAbsoluteUrl(iconPath)} className='svg-icon-1 svg-icon-primary' />
            </span>

            <div className='d-flex flex-column column-start flex-grow-1 align-items-start'>
                <span className='text-gray-900 fw-bolder text-hover-primary'>
                    {itemText}
                </span>
                <span className='fw-bold text-gray-700'>
                    {description}
                </span>
            </div>

            <div className="cursor-pointer badge badge-danger fs-6 fw-normal" onClick={handleCopyClick}>
                {
                    isTextCopied ? 'copied' : 'copy'
                }
            </div>
        </div>
    )
}

export { CopiableItem }