import { FC } from "react"
import { toAbsoluteUrl } from "../../../../../_metronic/helpers"
import { CardProps } from "../../aboutSection/components/ServiceCard"


const ResourceCard: FC<CardProps> = ({
    icon,
    title,
    description,
}) => {
    return (
        <div
            className='card card-border card-flush border border-2 border-gray-500 border-hover h-100 cursor-pointer bg-hover-light-dark'
        >
            <div className='d-flex flex-center align-self-stretch py-5 justify-content-between'>
                <div className='d-flex fs-2 text-dark-25 ls-1  flex-column align-self-stretch  m-0 ps-7 h-24px w-100 '>
                    {title}
                </div>

                <div className='card-toolbar ps-5'>
                    <div className='symbol symbol-50px w-50px'>
                        <img src={toAbsoluteUrl(icon)} alt='service illustration' className='pe-3' />
                    </div>
                </div>
            </div>

            <div className='px-7 h-24px w-100 '>
                <p className='text-dark-50 fw-semibold fs-5 mt-1 '>{description}</p>
            </div>
      </div >
    )
  }

export { ResourceCard }
