import { Navigate, Outlet, Route, Routes } from 'react-router-dom'
import { SolutionsContent } from './solutionsSection/components/Solutions'
import { ResourcesContent } from './resoursesSection/components/Resources'
import { ContactSection } from './contactSection/components/Contact'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import { NetZacHeader } from './NetZacHeader'
import { AboutSection } from './aboutSection/AboutPage'




const profileBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/home/aboutNetzac',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const HomePageContent = () => (
  <Routes>
    <Route
      element={
        <>
          <NetZacHeader />
          <Outlet />
        </>
      }
    >
      <Route
        path='aboutNetzac'
        element={
          <>
            <PageTitle breadcrumbs={profileBreadCrumbs}>{'About Netverse'}</PageTitle>
            <AboutSection />
          </>
        }
      />
      <Route
        path='solutions'
        element={
          <>
            <PageTitle breadcrumbs={profileBreadCrumbs}>{'Solutions'}</PageTitle>
            <SolutionsContent />
          </>
        }
      />
      <Route
        path='resources'
        element={
          <>
            <PageTitle breadcrumbs={profileBreadCrumbs}>{'Resources'}</PageTitle>
            <ResourcesContent />
          </>
        }
      />
      <Route
        path='reachUs'
        element={
          <>
            <PageTitle breadcrumbs={profileBreadCrumbs}>{'Reach us'}</PageTitle>
            <ContactSection />
          </>
        }
      />
      <Route index element={<Navigate to='/home/aboutNetzac' />} />

      {/* <Route
        path='/home/portal-modules'
        element={
          <>
            <PageTitle breadcrumbs={profileBreadCrumbs}>{'Portal Modules'}</PageTitle>
            <PortalModules />
          </>
        }
      /> */}
  
    </Route>
  </Routes>
)

export default HomePageContent
