
const ContactSection = () => {
    return (
        <div className="row mt-7 mb-5 justify-content-between gap-20">
            <div className="col-sm-12 col-lg-6 col-xl-4 gap-5" >
                <div className="d-flex flex-column">
                    <h1 className='fs-4x fw-bolder text-primary ls-2'>
                       Come work with us!
                    </h1>
                    <p className="text-gray-600px fs-2x mt-3 ">
                        Got something in mind you think we can work on together?
                    </p>
                </div>
            </div>
            <div className="col-sm-12 col-lg-6 col-xl-6 ">
                <p className="text-gray-600px fs-2x mb-2">Get in touch with supa-fast response⚡ </p>
                <form className="d-flex flex-column align-self-stretch gap-5">

                    <div className="d-flex flex-lg-row justify-content-between gap-5">
                        <div className="d-flex flex-column py-1 bg-light-dark w-100 align-items-start align-self-stretch rounded-100 ">
                            <div className="d-flex flex-row align-items-center px-4 w-100">
                                <i className="bi bi-person-fill text-gray-700"></i>
                                <input className="form-control form-control-lg form-control-transparent bg-light-dark" type="text" placeholder="First name" />
                            </div>
                        </div>
                        <div className="d-flex flex-column py-1 bg-light-dark w-100 align-items-start align-self-stretch rounded-100 ">
                            <div className="d-flex flex-row align-items-center px-4 w-100">
                                <i className="bi bi-person-fill text-gray-700"></i>
                                <input className="form-control form-control-lg form-control-transparent w-100" type="text" placeholder="Last Name" />
                            </div>
                        </div>
                    </div>

                    <div className="d-flex flex-column py-1 bg-light-dark w-100 align-items-start align-self-stretch rounded-100 ">
                        <div className="d-flex flex-row align-items-center px-4 w-100">
                            <i className="bi bi-envelope-fill text-gray-700"></i>
                            <input className="form-control form-control-lg form-control-transparent w-100" type="email" placeholder="Email" />
                        </div>
                    </div>

                    <div className="d-flex flex-column py-1 bg-light-dark w-100 align-items-start align-self-stretch rounded-100 ">
                        <div className="d-flex flex-row align-items-center px-4 w-100">
                            <i className="bi bi-telephone-fill text-gray-700"></i>
                            <input className="form-control form-control-lg form-control-transparent w-100" type="phone" placeholder="Phone" />
                        </div>
                    </div>
                    <div className="d-flex flex-column  bg-light-dark w-100 align-items-start align-self-stretch rounded-24">
                        <div className="d-flex flex-column w-100 ">
                            <textarea className="form-control form-control-lg form-control-transparent" rows={8} placeholder="Message"></textarea>
                        </div>
                    </div>
                    <div className="d-flex flex-column flex-lg-row w-xl-50 w-lg-50 w-50 mt-2">
                        <button className="btn btn-m btn-primary btn-outline">Send message</button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export { ContactSection };
