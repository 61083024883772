import { FC } from "react"
import { KTSVG } from "../../../../../_metronic/helpers"

export type CardProps = {
    icon: string
    title: string
    description: string
    color?: string
}

  const ServiceCard: FC<CardProps> = ({
    color = 'primary',
    icon,
    title,
    description,
}) => {
    return (
        <div className='d-flex align-items-center mb-7'>
          {/* begin::Symbol */}
          <div className='symbol symbol-50px me-5'>
            <span className = {`symbol-label bg-light-${color} me-2`}>
              <KTSVG
                path= {icon}
                className={`svg-icon-2x svg-icon-${color}`}
              />
            </span>
          </div>
          {/* end::Symbol */}
          {/* begin::Text */}
          <div className='d-flex flex-column'>
            <span className={`text-dark text-hover-${color} fs-2 ls-1  fw-bold`}>
              {title}
            </span>
            <span className='text-muted fw-semibold fs-5'>
                {description}
            </span>
          </div>
          {/* end::Text */}
        </div>
    )
}

export { ServiceCard }