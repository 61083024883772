import { VerticalAboutHeader } from "./components/VerticalAboutHeader";
import { ServiceCard } from "./components/ServiceCard";
import { StaffCard2 } from "./components/StaffCard";
import { listOfStaff } from "./components/StaffModel";
import React from 'react'

const listOfServices = [
  {
    title: 'Online Tutoring',
    description: '',
    icon: '/media/icons/duotune/general/gen025.svg',
    color: 'primary'
  },
  {
    title: 'In-Person Training',
    description: '',
    icon: '/media/icons/duotune/general/gen025.svg',
    color: 'success'
  },

  {
    title: 'Hardware Installation',
    description: '',
    icon: '/media/icons/duotune/general/gen025.svg',
    color: 'info'
  },
  {
    title: 'Network Installation and Support',
    description: '',
    icon: '/media/icons/duotune/general/gen025.svg',
    color: 'warning'
  },
]


const AboutSection = () => {
    return (
      <>
        <VerticalAboutHeader />

        <div className='d-flex flex-column  align-items-center justify-content-center my-7'>
          <h3 className="fw-bolder fs-3x text-gray-900 pt-9 text-align-center">What's at Netverse?</h3>
          <p className="text-muted text-center text-align-center w-lg-50 w-xxl-50 fs-4">
            Netverse is a technology company that provides a wide range of services to our clients. We are
            dedicated to providing the best service to our clients.
          </p>
        </div>
        <div className='row g-6 g-xl-9 my-7 bg-gray-100 rounded-3 px-20 py-10'>
          {
            listOfServices.map((service, index) => (
              <div className='col-sm-6 col-xl-4' key={index}>
                <ServiceCard
                  title={service.title}
                  description={service.description}
                  icon={service.icon}
                  color={service.color}
                />
              </div>
            ))
          }
        </div>
        {/*<div className="d-flex flex-row w-xl-200px w-lg-200px w-50 text-gray-900 ">*/}
        {/*  <div className="btn btn-outline border border-gray-500 text-hover-primary rotate gap-2"*/}
        {/*       onClick={(e) => e.currentTarget.classList.toggle('active')}>*/}
        {/*    <KTSVG*/}
        {/*      path="/media/icons/duotune/arrows/arr072.svg"*/}
        {/*      className="svg-icon-2 rotate-180"*/}
        {/*    />*/}
        {/*    <span>Show more</span>*/}
        {/*  </div>*/}
        {/*</div>*/}

        <div className="d-flex flex-column align-items-center justify-content-center text-gray-800 mb-2 py-7">
          <h3 className="fw-bolder fs-3x">Our Great Team</h3>
          <p className={"text-muted w-lg-50 w-xxl-50 text-align-center fs-4"}>
            Our goal is to provide the best service to our clients. We have a team of professionals who are
            dedicated to providing the best service to our clients.
          </p>
        </div>

        <div className='row g-6 g-xl-12 py-7 carousel-image'>
          {
            listOfStaff.map((staff, index) => (
              <div className='col-md-3 col-xxl-3 col-lg-4 col-sm-4 col-6' key={index}>
                <StaffCard2
                  avatar={staff.image}
                  name={staff.name}
                  job={staff.jobTitle}
                  online={staff.online}
                  color={staff.color}
                  className="carousel-image-item"
                />
              </div>
            ))
          }
        </div>
      </>

    );
}

export {AboutSection};
